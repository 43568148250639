.header-wrapper{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-left: 65px;
    padding-top: 40px;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    margin: 0px auto;
    right: 0;

    @media (max-width: 767px) {
        padding-left: 15px;
        padding-top: 20px;
    }
    .header-inner{
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
    }
    .hamburger-menu{
        width: auto;
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
        pointer-events: all;
        transition: .3s opacity ease-in-out;
        z-index: 1;

        .hamburger-icon{
            height: 68px;
            width: 68px;
            background-color: #fff;
            border-radius: 6px;
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);   
            padding: 23px 18px;
            display: flex;
            flex-direction: column;
            position: relative;
            justify-content: center;
            align-items: center;
            margin-right: 34px;
            cursor: pointer;
            cursor: pointer;
            @media (max-width: 576px) {
                padding: 5px;
                width: 40px;
                height: 40px;
            }
            
            span{
                width: 30px ;
                height: 3px;
                background-color: #000;
                content: '';
                position: absolute;
                @media (max-width: 576px) {
                    width: 20px ;
                    height: 2px;
                }
                &:nth-child(1){
                    top: 23px;
                    @media (max-width: 576px) {
                        top: 25px;
                    }
                }
                &:nth-child(2){
                    bottom: 23px;
                    @media (max-width: 576px) {
                        bottom: 25px;
                    }
                }
            }
        }
        .hamburger-item-wrapper{
            width: 296px;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 6px;
            overflow: hidden;
            padding: 109px 35px 35px 35px;
            z-index: 9;
            pointer-events: none;
            @media (max-width: 576px) {
                padding: 80px 25px 25px 25px;
                width: 220px;
            }
            &::after{
                width: 800px;
                height: 800px;
                content: "";
                position: absolute;
                background-color: #fff;
                left: -50%;
                top: -50%;
                z-index: -1;
                transform: scale(0.00);
                transform-origin: left top;
                transition: .8s transform ease-in-out;
                border-radius: 50%;
            }
            .close-icon{
                width: 28px;
                height: 28px;
                position: absolute;
                left: 36px;
                top: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 0;
                transition: .3s opacity ease-in-out;
                transition-delay: .2s;
                cursor: pointer;
                
                span{
                    width: 36px ;
                    height: 3px;
                    background-color: #000;
                    content: '';
                    position: absolute;
                    @media (max-width: 576px) {
                        width: 25px ;
                    }
                    &:nth-child(1){
                        transform: rotate(45deg);
                    }
                    &:nth-child(2){
                        transform: rotate(-45deg);
                    }
                }
            }
            .hamburger-item-inner{
                padding: 0px;
                margin: 0px;
                list-style: none;
                li{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    text-decoration: none;
                    margin-bottom: 15px;
                    opacity: 0;
                    transition: .3s opacity ease-in-out;
                    transition-delay: .2s;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    a{
                        font-family: "HelveticaNeue";
                        font-weight: 400;
                        font-size: 28px;
                        line-height: 1.5;
                        color: #656565;
                        position: relative;
                        transition: color .3s  ease-in-out ,font-weight .03s  ease-in-out;
                        @media (max-width: 576px) {
                            font-size: 22px;
                        }
                        &::after{
                            width: 9px;
                            height: 9px;
                            content: '';
                            position: absolute;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            border-right: 2px solid #0079FF;
                            border-bottom: 2px solid #0079FF;
                            transform: rotate(-45deg);
                            opacity: 0;
                            transition: .3s opacity ease-in-out;
                        }
                        &:hover{
                            color: #0079FF;
                            font-weight: 700;
                        }
                        &:hover::after{
                            opacity: 1;
                        }
                    }
                }
            }
            
        }
        &.active{
            .hamburger-item-wrapper{
                pointer-events: all;
            }
            .close-icon{
                opacity: 1;
                transition-delay: .5s;
            }
            .hamburger-item-wrapper li{
                opacity: 1;
                transition-delay: .5s;
            }
            .hamburger-item-wrapper::after{
                transform: scale(1);
            }
        }
    }





    



    .logo-wrapper{
        width: 241px;
        display: flex;
        flex-direction: column;
        position: relative;
        height: 68px;
        justify-content: center;
        transition: .3s transform ease-in;
        transform: translateX(102px);
        @media (max-width: 767px) {
            width: 160px;
        }
        @media (max-width: 576px) {
            width: 120px;
            height: 40px;
            transform: translateX(55px);
        }
        .logo-container{
            width: 100%;
            position: relative;
            padding-bottom: calc((39 / 241) * 100%);
            img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
    }


    .extra-info-wrapper{
        width: auto;
        display: flex;
        flex-direction: column;
        position: relative;
        margin-left: auto;
        padding-right: 50px;
        opacity: 0;
        transition: .3s opacity ease-in-out;
        pointer-events: none;
        @media (max-width: 767px) {
            padding-right: 15px;
        }
        .action-wrapper{
            width: 100%;
            display: flex;
            position: relative;
            .action-item{
                width: auto;
                position: relative;
                .login-link{
                    font-family: 'Helvetica Neue';
                    font-size: 16px;
                    line-height: 1;
                    color: #485550;
                    font-weight: 400;
                    margin-right: 44px;
                    transition: .3s color ease-in-out;
                    @media (max-width: 767px) {
                        margin-right: 20px;
                    }
                    @media (max-width: 576px) {
                        font-size: 14px;
                        margin-right: 15px;
                    }
                    &:hover{
                        color: #0276F2;
                    }
                }
                .signup-link{
                    font-family: 'Helvetica Neue';
                    font-size: 16px;
                    line-height: 1;
                    color: #0276F2;
                    font-weight: 400;
                    transition: .3s color ease-in-out;
                    border: 1px solid #0276F2;
                    border-radius: 10px;
                    padding: 15px 18px;
                    background-color: #fff;
                    transition: .3s background-color ease-in-out,  .3s color ease-in-out;
                    @media (max-width: 767px) {
                        padding: 10px 13px;
                    }
                    @media (max-width: 576px) {
                        font-size: 14px;
                        padding: 8px 13px;
                    }
                    &:hover{
                        background-color: #0276F2;
                        color: #fff;
                    }
                }
            }
        }
    }
    &.active{
        .logo-wrapper{
            transform: translateX(0px);
        }
        .extra-info-wrapper{
            opacity: 1;
            pointer-events: all;
        }
        .hamburger-menu{
            opacity: 0;
            pointer-events: none;
        }
        
    }
}